import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ResetForm } from '../components/ResetForm';

const { Content } = Layout;

export const ResetPassword = () => {
    const navigate = useNavigate();

    return (
        <Content>
            <ResetForm onSuccess={() => navigate('/auth/login')}/>
        </Content>
    );

}
