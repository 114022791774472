import { Image, Carousel, Card, Row, Col, Typography } from 'antd';

import './style.css';

const { Meta } = Card;
const { Text } = Typography;

export const PriceList = () => {

    const data = [
        {
            name: 'SHION',
            brand: 'IGET',
            cost: 5.5,
            sell: 20,
            image_url: "https://igetvapesaustralia.com/imgs/igetshion/cola-ice-iget-shion.png",
            flavour: "Cola Ice",
            stock: 8,
        },
        {
            name: 'SHION',
            brand: 'IGET',
            cost: 5.5,
            sell: 20,
            image_url: "https://igetvapesaustralia.com/imgs/igetshion/blueberry-ice-iget-shion.png",
            flavour: "Blueberry Ice",
            stock: 8,
        },
        {
            name: 'SHION',
            brand: 'IGET',
            cost: 5.5,
            sell: 20,
            image_url: "https://igetvapesaustralia.com/imgs/igetshion/strawberry-watermelon-iget-shion.png",
            flavour: "Strawberry Watermelon",
            stock: 7,
        },
        {
            name: 'SHION',
            brand: 'IGET',
            cost: 5.5,
            sell: 20,
            image_url: "https://igetvapesaustralia.com/imgs/igetshion/grape-iget-shion.png",
            flavour: "Grape",
            stock: 8,
        },
        {
            name: 'SHION',
            brand: 'IGET',
            cost: 5.5,
            sell: 20,
            image_url: "https://igetvapesaustralia.com/imgs/igetshion/mango-iget-shion.png",
            flavour: "Mango",
            stock: 8,
        },
        {
            name: 'SHION',
            brand: 'IGET',
            cost: 5.5,
            sell: 20,
            image_url: "https://igetvapesaustralia.com/imgs/igetshion/strawberry-iget-shion.png",
            flavour: "Strawberry",
            stock: 9,
        }
    ];
    
    const addToCart = () => {

    }

    const onChange = (e: any) => {
        console.log(e);
    }

    return (
        <Carousel afterChange={onChange}>
            {data && data.map((item: any, index: number) => {
                return (
                        <Card 
                            className="price-item-card" 
                            cover={<Image width={100} preview={false} alt="item-image" src={item.image_url} />}
                            actions={[
                                <Text>Stock: {item.stock}</Text>
                            ]}
                        >
                        <Meta 
                            title={`${item.brand} ${item.name}`}
                            description={item.flavour}
                        />
                        </Card>
                );
            })}
        </Carousel>
    );
}
