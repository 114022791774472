import { useState } from 'react';
import { Alert, Typography, Button, Input, Form } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { requestResetHandler } from '../api/requestReset'; 

import '../style.css';

const { Title, Text } = Typography;

type RequestResetFormProps = {
    onSuccess: () => void;
}

export const RequestResetForm = ({ onSuccess }: RequestResetFormProps) => {
    const [error, setError] = useState('');

    const onSubmit = async (formData: any) => {
        try { 
            await requestResetHandler(formData);
            onSuccess();
        } catch (e: any) {
            setError(e.message);
        }
    }

    return (
        <div className="auth-form">
            <Form onFinish={onSubmit}>
                <LockOutlined  style={{ fontSize: '4rem', color: '#03adf4' }}/>
                <Title>Reset Password</Title>
                <br />
                <Alert message="Please enter your email address and you will recieve a email with a password reset link." type="info" />
                <hr />
                {error && <Alert message={error} type="error" />}
                <br />
                <Form.Item name="email">
                    <Input type="email" placeholder="Email Address" />
                </Form.Item>
                <Button type="primary" htmlType="submit" shape="round" block>Reset</Button>
            </Form>
        </div>
    );

}
