import { Space, Row, Col, Layout, Button, Typography } from 'antd';
import { useNavigate } from 'react-router';
import { useAuth } from 'lib/auth';
import { PriceList } from 'components/PriceList';

import '../style.css';

const { Title } = Typography;
const { Content } = Layout;

export const Landing = () => {
    const navigate = useNavigate();
    const { user } = useAuth();

    const handleStart = () => {
        if(user) {
            navigate('/app');
        } else {
            navigate('/auth/login');
        }
    }

    const orderStatusHandler = () => {

    }

    const placeOrderHandler = () => {

    }

    return (
        <Content className="landing-page">
            <Row justify="center" align="middle" style={{ height: '100vh'}}>
                <Col span={12}>
                    <div className="landing-content">
                        <Title>Jindy Vape Guy</Title>
                        <Button type="primary" size="large" style={{ borderRadius: '0.25rem' }}>Order Now</Button>
                        <hr />
                        <PriceList />
                    </div>
                </Col>
            </Row>
        </Content>
    );
}
