import { Row, Col } from 'antd';
import { Route, Routes } from 'react-router-dom';

import { Login } from './Login';
import { ResetPassword } from './ResetPassword';
import { RequestReset } from './RequestReset';

export const AuthRoutes = () => {
    return (
        <Row gutter={[16, 16]} className="auth-page">
            <Col span={24} xl={6}>
                <Routes>
                    <Route path="login" element={<Login />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route path="request-reset" element={<RequestReset />} />
                </Routes>
            </Col>
        </Row>
    );
};
