import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';

import { LoginForm } from '../components/LoginForm';
const { Content } = Layout;

export const Login = () => {
    const navigate = useNavigate();

    return (
        <Content>
            <LoginForm onSuccess={() => navigate('/')}/>
        </Content>
    );

}
