import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';

import { lazyImport } from 'utils/lazyImport';

const { Home } = lazyImport(() => import('features/home'), 'Home');

const App = () => {
    return (
        <div className="main-layout">
            <Suspense fallback={
                <div className="loading-spinner">
                    <SyncOutlined spin />
                </div>
            }>
                <Outlet />
            </Suspense>
        </div>
    );
};

export const protectedRoutes = [
    {
        path: '/',
        element: <App />,
        children: [
            { path: '/', element: <Home /> },
        ],
    },
];
