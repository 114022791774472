import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RequestResetForm } from '../components/RequestResetForm';

const { Content } = Layout;

export const RequestReset = () => {
    const navigate = useNavigate();

    return (
        <Content>
            <RequestResetForm onSuccess={() => navigate('/auth/login')}/>
        </Content>
    );

}
