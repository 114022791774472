import * as React from 'react';
import { Button, Layout } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from 'react-query';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import { WarningOutlined, SyncOutlined } from '@ant-design/icons';

import { AuthProvider } from 'lib/auth';
import { queryClient } from 'lib/react-query';

const ErrorFallback = () => {
    return (
        <Layout className="layout-style">
            <div className="error-page">
                <div className="error-content">
                    <WarningOutlined style={{ fontSize: '6rem' }} />
                    <h2>An error has occured</h2>
                    <br />
                    <Button type="primary" style={{ borderRadius: '0.5rem' }} onClick={() => window.location.assign(window.location.origin)}>
                        Refresh
                    </Button>
                </div>
            </div>
        </Layout>
    );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
    return (
        <React.Suspense
          fallback={
              <Layout>
                  <div className="loading-spinner">
                      <SyncOutlined spin />
                  </div>
              </Layout>
          }
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <CookiesProvider>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <Router>{children}</Router>
                    </AuthProvider>
                </QueryClientProvider>
            </CookiesProvider>
          </ErrorBoundary>
        </React.Suspense>
    );
};
