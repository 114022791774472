import './App.css';

import { AppRoutes } from './routes';
import { AppProvider } from './providers/App';

export const App = () => {

    return (
        <AppProvider>
            <AppRoutes />
        </AppProvider>
    );
}
