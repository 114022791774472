import { initReactQueryAuth } from 'react-query-auth';
import { SyncOutlined } from '@ant-design/icons';
import { 
    getUser, 
    loginHandler, 
    registerHandler,
    LoginCredentialsDTO, 
    RegisterCredentialsDTO, 
    UserResponse, 
    AuthUser
} from 'features/auth';
import storage from 'utils/storage';

async function handleUserResponse(data: UserResponse) {
    const user = data.user;
    const token = data.token;
    storage.setToken(token);
    return user;
}

async function loadUser() {
    if (storage.getToken()) {
        const data = await getUser();
        return data;
    }
    return null;
}

async function loginFn(data: LoginCredentialsDTO) {
    const response = await loginHandler(data);
    const user = await handleUserResponse(response);
    return user;
}

async function registerFn(data: RegisterCredentialsDTO) {
    const response = await registerHandler(data);
    const user = await handleUserResponse(response);
    return user;
}

async function logoutFn() {
    storage.clearToken();
    window.location.assign(window.location.origin as unknown as string);
}

const authConfig = {
    loadUser,
    loginFn,
    registerFn,
    logoutFn,
    LoaderComponent() {
        return (
            <div className="loading-spinner">
                <SyncOutlined spin />
            </div>
        );
    },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
    AuthUser | null,
    unknown,
    LoginCredentialsDTO,
    RegisterCredentialsDTO
>(authConfig);
