import { API_URL } from 'config';

const handleApiResponse = async (response: any) => {
    const data = await response.json();
    if (response.ok) {
        return data;
    } else {
        return Promise.reject(data);
    }
}

export const resetPasswordHandler = async (data: any): Promise<any> => {
    return await window.fetch(`${API_URL}/api/auth/reset-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(handleApiResponse);
}
