import { axios } from 'lib/axios';

export type RegisterCredentialsDTO = {
    username: string;
    email: string;
    password: string;
    role: string;
    welcome_email: boolean;
}

export const registerHandler = async (data: RegisterCredentialsDTO): Promise<any> => {
    return await axios.post('/api/auth/register', data);
}
