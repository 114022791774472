import { useState } from 'react';
import { Alert, Typography, Button, Input, Form } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { resetPasswordHandler } from '../api/resetPassword';

const { Title, Text } = Typography;

type ResetFormProps = {
    onSuccess: () => void;
}

export const ResetForm = ({ onSuccess }: ResetFormProps) => {
    const [error, setError] = useState('');
    const [searchParams] = useSearchParams();

    const onSubmit = async (formData: any) => {
        const token = searchParams.get('token') as string;
        const userId = searchParams.get('id') as string;
        if(formData.password === formData.confirm_password) {
            const data = {
                token: token,
                userId: userId,
                password: formData.password
            };
            await resetPasswordHandler(data);
            onSuccess();
        } else {
            setError("Passwords Do Not Match");
        }
    }

    return (
        <div className="auth-form">
            <Form onFinish={onSubmit}>
                <LockOutlined  style={{ fontSize: '4rem', color: '#03adf4' }}/>
                <Title>Reset Password</Title>
                {error && <Alert message={error} type="error" />}
                <br />
                <Form.Item name="password">
                    <Input type="password" placeholder="New Password" />
                </Form.Item>
                <Form.Item name="confirm_password">
                    <Input type="password" placeholder="Confirm Password" />
                </Form.Item>
                <Button type="primary" htmlType="submit" block style={{ borderRadius: '0.5rem' }}>Reset</Button>
            </Form>
        </div>
    );

}
