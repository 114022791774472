import { useState } from 'react';
import { Alert, Typography, Input, Form, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import '../style.css';

import { useAuth } from 'lib/auth';

const { Title } = Typography;

type LoginFormProps = {
    onSuccess: () => void;
}

export const LoginForm = ({onSuccess}: LoginFormProps) => {
    const [error, setError] = useState();
    const { login } = useAuth();
    const navigate = useNavigate();

    const loginHandler = async (formData: any) => {
        try {
            await login(formData);
            onSuccess();
        } catch (e: any) {
            setError(e.message);
        }
    }

    return (
        <Form onFinish={loginHandler} className="auth-form">
            <LockOutlined  style={{ fontSize: '4rem', color: '#03adf4' }}/>
            <Title>RMS Login</Title>
            {error && <Alert message={error} type="error" />}
            <br />
            <Form.Item name="email">
                <Input placeholder="Email" />
            </Form.Item>
            <Form.Item name="password">
                <Input type="password" placeholder="Password" />
            </Form.Item>
            <Button type="primary" htmlType="submit" style={{ borderRadius: '0.5rem' }}>Login</Button>
            <br />
            <Button type="link" onClick={() => navigate('/auth/request-reset')}>Forgot password?</Button>
        </Form>
    )
}
